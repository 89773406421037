<template lang="pug">
  .reservation-fee-brakedown
    Title(title="ota_management.booking_confirmation.form.fee_breakdown.title")
</template>

<script>
  export default {
    components: {
      Title: () => import("@/components/CompanySystem/Title"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel")
    },

    props: {
      feeBreakdown: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
</style>
